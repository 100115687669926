(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/customer-service-smartfaq/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/modules/customer-service-smartfaq/assets/javascripts/init.js');
'use strict';

const smartFaq = document.getElementById('smartfaq');
const header = document.getElementsByClassName('js-top-content-wrapper')[0].clientHeight;
const setHeight = () => {
  const newHeight = Math.round(window.innerHeight - header);
  smartFaq.height = newHeight;
};
const setHeightOnLoad = () => {
  if (parseInt(smartFaq.height, 10) > 0) {
    window.requestAnimationFrame(setHeightOnLoad);
  } else {
    setHeight();
  }
};
setHeightOnLoad();
window.addEventListener('resize', setHeight, false);
window.addEventListener('orientationchange', setHeight, false);

 })(window);